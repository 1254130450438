$(document).ready(function() {


    $('#toggle-sidebar').on('click', function() {
        $('.nav-list-items').toggleClass('slide-nav-list');
        $('.hamburger span').toggleClass('animate-hamburger');
        $('.hamburger span').toggleClass('w-0');
        $('body').toggleClass('body-overflow');
    });


    $('.fadein-up').each(function() {
        if($(this).visible()) {
            $(this).addClass('fadein-active');
        }
    })

    $('.instr-number').each(function() {
        if($(this).visible()) {
            /* $(this).addClass('move-cycle-1');
            $(this).addClass('color-light'); */
            $(this).closest('.instruction-step').find('.fadein-up-instr').addClass('fadein-active');
        }
    })
   
    $(window).scroll(function(){

        // visible() function - js plugin
        $('.fadein-up').each(function() {
            if($(this).visible()) {
                $(this).addClass('fadein-active');
            }
        });
    });


    $(window).on('scroll', function() {
        if($(window).scrollTop() > 70) {
            $('.header-logo img').addClass('resized-logo');
            $('.navigation').css('filter','drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.56))');
        } else {
            $('.header-logo img').removeClass('resized-logo');
            $('.navigation').css('filter','none');
        }
    });

    
    /* contact form - send message */
    $('.js-btn-send').on('click', function() {
        $('.to-hide').slideToggle();
        $('.to-show').slideToggle();

        
        setTimeout(function() {
            $('html, body').animate({
                scrollTop: $(".to-show").offset().top-80
            }, 1000);
        }, 400);
        
    });

    $('.lng').on('click', function() {
        $('.hidden-lng').toggle();
    });
    

});

/* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */


  $('.dropbtn').on('click', function() {
    document.getElementById("myDropdown").classList.toggle("show");
  })
  
  // Close the dropdown if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

$(document).ready(function(){
    $(document).mousemove(function(event){
        $('.artboard.overlay').css('width', event.pageX)
    });
});

$(document).ready(function(){
  $('.slides-wrapper').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    adaptiveHeight: true,
    arrows: false
  });
});