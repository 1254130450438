$(document).ready(function() {
    
    var chipsArray = [
        {
            id: 1,
            hex: '#ffffff',
            frame: 'white'
        },
        {
            id: 2,
            hex: '#212121',
            frame: 'black'
        },
        {
            id: 3,
            hex: '#F32F30',
            frame: 'red'
        },
        {
            id: 4,
            hex: '#FF8038',
            frame: 'orange'
        },
        {
            id: 5,
            hex: '#FBCE1C',
            frame: 'sun-yellow'
        },
        {
            id: 6,
            hex: '#FDE972',
            frame: 'lemon-yellow'
        },
        {
            id: 7,
            hex: '#8BCE68',
            frame: 'green'
        },
        {
            id: 8,
            hex: '#72C9E2',
            frame: 'sky-blue'
        },
        {
            id: 9,
            hex: '#3B7FA4',
            frame: 'ocean-blue'
        },
        {
            id: 10,
            hex: '#ECC8C5',
            frame: 'pink'
        }
    ];
    
    var framesArray = [
        {
            id: 1,
            imgName: 'Sticker1.jpg',
            hex: 'ffffff',
            color: 1
        },
        {
            id: 2,
            imgName: 'Sticker2.jpg',
            hex: 'F32F30',
            color: 3
        },
        {
            id: 4,
            imgName: 'Sticker4.jpg',
            hex: 'FDE972',
            color: 6
        },
        {
            id: 3,
            imgName: 'Sticker3.jpg',
            hex: 'F32F30',
            color: 3
        },
        {
            id: 5,
            imgName: 'Sticker5.jpg',
            hex: 'ECC8C5',
            color: 10
        },
        {
            id: 6,
            imgName: 'Sticker6.jpg',
            hex: '72C9E2',
            color: 8
        },
        {
            id: 7,
            imgName: 'Sticker7.jpg',
            hex: 'FBCE1C',
            color: 5
        },
        {
            id: 10,
            imgName: 'Sticker10.jpg',
            hex: '212121',
            color: 2
        },
        {
            id: 9,
            imgName: 'Sticker9.jpg',
            hex: 'FF8038',
            color: 4
        },
        {
            id: 8,
            imgName: 'Sticker8.jpg',
            hex: '8BCE68',
            color: 7
        },
        {
            id: 11,
            imgName: 'Sticker11.jpg',
            hex: 'ECC8C5',
            color: 10
        },
        {
            id: 12,
            imgName: 'Sticker12.jpg',
            hex: '3B7FA4',
            color: 9
        },
        {
            id: 14,
            imgName: 'Sticker14.jpg',
            hex: '212121',
            color: 2
        },
        {
            id: 13,
            imgName: 'Sticker13.jpg',
            hex: 'FF8038',
            color: 4
        },
        {
            id: 15,
            imgName: 'Sticker15.jpg',
            hex: 'FDE972',
            color: 6
        },
        {
            id: 16,
            imgName: 'Sticker16.jpg',
            hex: '212121',
            color: 2
        },
        {
            id: 17,
            imgName: 'Sticker17.jpg',
            hex: 'FDE972',
            color: 6
        },
        {
            id: 18,
            imgName: 'Sticker18.jpg',
            hex: 'ffffff',
            color: 1
        },
        {
            id: 19,
            imgName: 'Sticker19.jpg',
            hex: 'ECC8C5',
            color: 10
        }
    ];

    /* first generate chip buttons, than all-chips button */
    chipsArray.forEach(generateChipsInitial);
    $('.chips-wrapper').append('<div class="show-all-frames">Sve</div>');
    

    function generateChipsInitial(item) {
        $('.chips-wrapper').append(
            '<div class="single-chip" data-color-chip="' + item.id + '" style="background-color:' + item.hex + '"></div>'
        )
    }


    framesArray.forEach(generateFramesInitial);

    function generateFramesInitial(item) {
        var frameName = null;
        chipsArray.forEach(function(subItem) {
            if(item.color == subItem.id) {
                frameName = subItem.frame;
            }
        });
        $('.gallery-box .row').append(
            '<div class="col-12 col-sm-6 col-md-3 text-center mb-4 gallery-item is-visible" data-frame-id="'+ item.id +'" data-color-hex="'+ item.hex +'">' + 
                '<a class="test-popup-link" href="/images/stickers/' + item.imgName + '">' +
                    '<div class="notebook-imgs-wrapper">' + 
                        '<img src="/images/'+ frameName +'.jpg" alt="">' + 
                        '<div class="center-img" style="background-image:url(/images/stickers/' + item.imgName + ')">' + 
                        '</div>' + 
                    '</div>' + 
                '</a>' + 
                '<a href="https://localhost:44374/editor?image=/images/stickers/' + item.imgName + '&color='+ item.hex +'" target="_blank"><div class="add-to-editor mx-auto w-100 mt-4 mb-4">Dodaj u editor</div></a>' + 
            '</div>'
        )
    }


    function filterByColor(chipColor) {
        framesArray.forEach(function(item) {
            if (item.color == chipColor) {

                $('.gallery-item').each(function() {
                    if($(this).attr('data-frame-id') == item.id) {
                        $(this).addClass('is-visible');
                        $(this).addClass('bounceIn');
                        $(this).removeClass('d-none');
                    }
                })
            }
        });
    }


    var transitionNameShow      = 'bounceIn';
    var transitionNameHide      = 'bounceOut';
    var transitionLasting       = 700;

    $('.single-chip').on('click', function () {

        $('.single-chip').removeClass('active-chip');
        $(this).addClass('active-chip');
        

        $('.gallery-item').removeClass('is-visible');
        $('.gallery-item').addClass(transitionNameHide);

        setTimeout(function() {
            $('.gallery-item').addClass('d-none');
        }, transitionLasting);
  

        var chipColor = $(this).attr('data-color-chip');

        // call function to filter frames by color
        setTimeout(function() {
            filterByColor(chipColor);
            $('.gallery-item').removeClass(transitionNameHide);
        }, transitionLasting);
        

        setTimeout(function() {
            $('html, body').animate({
                scrollTop: $(".gallery-items-wrapper").offset().top-80
            }, 1000);
        }, transitionLasting);

    });

    $('.show-all-frames').on('click', function() {

        $('.single-chip').removeClass('active-chip');        

        $('.gallery-item').removeClass('is-visible');
        $('.gallery-item').addClass(transitionNameHide);

        setTimeout(function() {
            $('.gallery-item').addClass('d-none');
        }, 700);

        setTimeout(function() {
            $('.gallery-item').addClass('is-visible').addClass('bounceIn').removeClass('d-none').removeClass(transitionNameHide)
        }, transitionLasting);
        
        setTimeout(function() {
            $('html, body').animate({
                scrollTop: $(".gallery-items-wrapper").offset().top-80
            }, 1000);
        }, transitionLasting);

    });
})


$(document).ready(function() {
    $('.test-popup-link').magnificPopup({
        type:'image'
    });
});